import * as React from "react"
import HomeIcon  from "../../images/HomeIcon.svg"
import WorkoutIcon from "../../images/workout_icon.svg"
import MenteIcon from "../../images/mente_icon.svg"
import ProfileIcon from "../../images/profile_icon.svg"
import {Link} from "gatsby"

const BottomNavigation = ({className, style , location}) => {

    const activeColor = (isActive) => isActive ? "#00493C" : "#C4C4C4"

    return (<div className={className} style={style}>
        <div className="flex w-full justify-between h-12 bg-white p-4 items-center">
            <div>
                <Link to="/">
                    <HomeIcon fill={activeColor(location && location.pathname === "/")}></HomeIcon>
                </Link> 
            </div>
            <div>
                <Link to="/sections/entrenamiento">
                   <WorkoutIcon fill={activeColor(location && location.pathname=== "/sections/entrenamiento")}></WorkoutIcon>
                </Link>
            </div>
            <div>
                <Link to="/sections/menteycuerpo">
                    <MenteIcon fill={activeColor(location && location.pathname=== "/sections/menteycuerpo")}></MenteIcon>
                </Link>
            </div>
            <div>
                <Link to="/profile">
                    <ProfileIcon fill={activeColor(location && location.pathname=== "/profile")}></ProfileIcon>
                </Link>
            </div>
        </div>
    </div>)
}
export default BottomNavigation