import React, { useState, useEffect } from 'react';
import { navigate } from 'gatsby'; // Asegúrate de importar navigate de gatsby

import SearchIcon from "@material-ui/icons/Search"
import CloseIcon from "@material-ui/icons/Close"

const SearchBox = ({ onSearch, section, sectionSelected}) => {
  const [searchTerm, setSearchTerm] = useState('');





  const navigateSearch = () => {
    const url = window.location.href;
    const urlParams = new URLSearchParams(window.location.search);
    let sectionId = urlParams.get("sectionId");

    if(sectionSelected === undefined){
      navigate(`/search?term=${encodeURIComponent(searchTerm)}&sectionId=${encodeURIComponent(sectionId)}`);
    }
    else{
      sectionId = sectionSelected === "home" ? "60f7ba875e39aa6aa19a36af" : sectionSelected;
      navigate(`/search?term=${encodeURIComponent(searchTerm)}&sectionId=${sectionId  }`);
    }
}

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSearchClick = () => {
    navigateSearch()

  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearchClick()
    }
  };

  return (
<div className="relative flex items-center pr-5">
  <button
    className="absolute left-0 ml-3"
    onClick={handleSearchClick}
  >
    <SearchIcon />
  </button>
  <input
    type="text"
    placeholder="Buscar..."
    className="bg-gray-100 border border-gray-300 rounded-md py-0.5 px-4 pl-10 focus:outline-none focus:ring focus:border-teal-500"
    value={searchTerm}
    onChange={handleInputChange}
    onKeyUp={handleKeyPress} // Detecta la tecla Enter
  />
  {searchTerm && (
    <button
      className="absolute right-0 mr-6"
      onClick={() => setSearchTerm("")}
    >
      <CloseIcon />
    </button>
  )}
</div>


  )
}

export default SearchBox

/* import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";



const SearchBox = (value) => {

    const [internalValue, setInternalValue] = React.useState(value)

    return(<div className="flex items-center bg-white mr-5 w-64 hidden " style={{height:40, borderRadius:6 , paddingLeft:10}}>
            <StaticImage width={18} height={18} src="../images/search_icon.png" alt="Icon Search" objectFit="contain" />
            <input type="text" className="border-none outline-none ml-2" placeholder="Buscar"></input>
    </div>)

}

export default SearchBox  */
