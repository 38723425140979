import * as React from "react";
import { Modal, Input, Checkbox, FormControlLabel } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import MailIcon from "@material-ui/icons/Mail";
import TextField from "@material-ui/core/TextField";
import { StaticImage } from "gatsby-plugin-image";
import SignInForm from "../forms/signInForm";
import SignUpForm from "../forms/signUpForm";
import ResetPasswordRequestForm from "../forms/passwordResetRequestForm";
import PasswordResetConfirm from "../forms/PasswordResetConfirm";
import PartnerCode from "../forms/partnerCode";
import PartnerSignUpForm from "../forms/partnerSignUpForm";

const SignInModal = ({ show, onClose }) => {

    const [form, setForm] = React.useState("login")
    const [nameHidden, setNameHidden] = React.useState()

    React.useEffect(() => {
        setForm("login")
    }, [show]);

    return (<Modal open={show} onClose={onClose}>
        <div className="bg-white relative mx-auto rounded-lg px-5 pt-8 pb-4 overflow-y-auto" style={{ top: "11%", maxHeight: "85%", width: 450 }}>
            {
                form !== "confirm" ? <header>
                    {false ?
                        <div className="flex justify-end">
                            <CloseIcon onClick={onClose}></CloseIcon>
                        </div> : null}
                    {form !== "reset" && form !== "comfirm" ?
                        <div className="mb-8 flex justify-center">
                            <StaticImage width={100} src="../../images/logo_black.png" alt="Shift Logo" objectFit="contain" ></StaticImage>
                        </div> : null}
                </header> : null
            }
            <>
                {form === "login" ? <SignInForm onSignUpRequest={() => { setForm("signup") }} onResetPasswordRequest={() => { setForm("reset") }} onAuthenticationSuccess={onClose} onDismiss={onClose}></SignInForm> : null}
                {form === "signup" ? <SignUpForm onSignInRequest={() => { setForm("login") }} onPartnerCodeRequest={() => { setForm("partnercode") }} onSignUpSuccess={onClose}></SignUpForm> : null}
                {form === "reset" ? <ResetPasswordRequestForm onResetRequest={() => { setForm("reset") }} onRequestSuccess={() => { setForm("confirm") }} onSignInRequqest={() => { setForm("login") }}></ResetPasswordRequestForm> : null}
                {form === "confirm" ? <PasswordResetConfirm onSignInRequest={() => { setForm("login") }} onDismiss={onClose} /> : null}
                {form === "partnercode" ? (
                    <PartnerCode
                        setNameHidden={setNameHidden}
                        onSignInRequest={() => {
                            setForm("login")
                        }}
                        onSignUpPartnerForm={() => {
                            setForm("partnerSignup")
                        }}
                    />
                ) : null}
                {form === "partnerSignup" ? (
                    <PartnerSignUpForm
                        onSignUpSuccess={onClose}
                        nameHidden={nameHidden}
                        onPartnerCodeRequest={() => {
                            setForm("partnercode")
                        }}
                        onSignInRequest={() => {
                            setForm("login")
                        }}
                    />
                ) : null}
            </>
            <footer></footer>
        </div>
    </Modal>)
}

export default SignInModal;
